import React, { useState, useEffect } from 'react';
import { useParams, Navigate, Link } from "react-router-dom";
import QRCode from "react-qr-code";
import AuthService from './AuthService';

export default function PlayerDetails(props) {
  const [player, setPlayer] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    const bearerToken = new AuthService().getToken();
    if(bearerToken != null) {
      fetch(`/players/${id}`, {
        method: 'GET',
        headers: {
          Authorization: bearerToken
        }
      })
      .then(resp => resp.json())
      .then(data => setPlayer(data))
    }
  }, [id])

  if((new AuthService()).loggedIn()) {
    return <>
      <div>
        <Link to="/players">Back</Link>
        <br/>
        <label> Name </label>
        <p> {player.name} </p>
        <p> {player.playerid} </p>
        {player.playerid && (<QRCode value={window.location.hostname+'/'+player.playerid} />)}
        </div>
      </>
  } else {
    return <Navigate to="/login" />
  }
}
